import React, { useEffect, useState } from 'react'

import { readRemoteFile } from 'react-papaparse'

/**
 * Query data form an external CSV (public Google Sheets).
 * We use papaparse since Google makes it so awful difficult to use their API without logging on.
 */

function CSV() {
  const [csvData, setCSVData] = useState({})

  // Public URL of our Google Sheet in CSV format
  const url =
    'https://docs.google.com/spreadsheets/d/1q-2HfibnDO09FFeZQk9VN5h2GNdKUfsuE3nWFYOGe48/pub?output=csv' // PROD
  //'https://docs.google.com/spreadsheets/d/1YkzDdYwrE_w6ZX3Limiu-nHn6KPXbHLuBHPXdfniOs0/pub?output=csv' // TEST

  /**
   * Date submitted via the form overwrites the Google Sheet format. Let's clean it up on client side
   * shame :(
   * Better would be to change it with a script in the sheet, but that would be yet again something to maintain...
   *
   * @param gDate: date receive from Google Sheet. Format 1-05-2021
   */

  function formatDate(gDate: string) {
    const dd = gDate.split('-')[0]
    let mm = gDate.split('-')[1].padStart(2, '0')

    mm = (parseInt(mm) - 1).toString() // January is 0

    switch (mm) {
      case '0':
        mm = 'januari'
        break
      case '1':
        mm = 'februari'
        break
      case '2':
        mm = 'maart'
        break
      case '3':
        mm = 'april'
        break
      case '4':
        mm = 'mei'
        break
      case '5':
        mm = 'juni'
        break
      case '6':
        mm = 'juli'
        break
      case '7':
        mm = 'augustus'
        break
      case '8':
        mm = 'september'
        break
      case '9':
        mm = 'oktober'
        break
      case '10':
        mm = 'november'
        break
      case '11':
        mm = 'december'
        break
      default:
        mm = (parseInt(mm) + 1).toString()
    }

    return `${dd} ${mm}`
  }

  function cssClass(formAnswer: string) {
    let cssClass = ''

    switch (formAnswer) {
      case 'gaat door':
        cssClass = 'bg-success'
        break
      case 'is digitaal':
        cssClass = 'bg-warning'
        break
      default:
        // If Joram thinks of any other option, it will likely not be a good one ;)
        cssClass = 'bg-danger text-white'
    }

    return cssClass
  }

  // Async, so we use useEffect hook
  useEffect(() => {
    readRemoteFile(url, {
      complete: (results) => {
        // Only take the last result into account
        let result = results.data.slice(-1)[0]

        // format the date
        result[2] = formatDate(result[2])

        // update state
        setCSVData(result)
      },
    })
  }, [])

  // As long as csvData is empty, we don't yet have the data
  if (!Object.keys(csvData).length) {
    return (
      <h2>Een ogenblik, we checken of de training door zal gaan.</h2>
    )
  } else {
    // Ok, we got it, render the data
    return (
      <>
        <h2>
          De training van{' '}
          <strong className={`${cssClass(csvData[1])}`}>
            {csvData[2]} {csvData[1]}
          </strong>.
        </h2>
        <p className="small pb-3">
          (Laatste update: {csvData[0]}. Meer informatie: zie hieronder.)<br />
        </p>
      </>
    )
  }
}

export default CSV
