import React from 'react'

import Layout from '@/components/Layout'
import CSV from '@/components/CSV'



function ParkTrainingPage() {
  return (

    <Layout
      pageTitle="Training in het park"
      heroTitle="Training in het park"
      heroSubTitle=""
      heroTagLine=""
      heroImage=""
      metaDescription="Trainingen in het park. Altijd op een veilige en verantwoorde manier!"
      noIndex={true}
    >

      <div className="row justify-content-md-center text-center">
        <div className="col-sm col-md-8 mb-5">
          <CSV />

          <h3>Waarom?</h3>

          <p>Omwille van de corona-maatregelen zijn indoor trainingen en contactsport momenteel verboden (voor +13 jaar).</p>

          <h3>Waar?</h3>
          <p>
            Sportcentrum Osbroek, Frans Blanckaertdreef te Aalst. Afspraak aan de eerste ingang nabij het tennisterrein.</p>

          <h3>Wanneer?</h3>
          <p>
            Maandag, Woensdag en vrijdag van 19:30 - 21:00
            <br />
          </p>

          <h3>Voor wie?</h3>
          <p>
            Alle seniores-leden
          </p>

          <h3>Afspraken?</h3>
          <p>
            Er wordt enkel getraind op afstand (slag- en schoptechnieken, kata, etc.) in groepen van maximaal vier personen.
            <br />
            Ten alle tijden wordt minstens 1.5 meter afstand gehouden.
            <br />
            Mondmasker wordt steeds gedragen (mag eventueel af wanneer de sportactiviteit gestart is).
            <br />
            Kledij: sportschoenen, trainingsbroek en club T-shirt met daardonder (dikke) trui met lange mouwen (alles aangepast aan de weerstomstandigheden)
          </p>
        </div>
      </div>

    </Layout>
  )
}

export default ParkTrainingPage



